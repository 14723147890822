import * as React from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { AdaPro } from '@/images/support/index'
import { DetailHeader, DetailStart, DetailFaq } from '@/components/Support/index'
import { useGTMInit} from '@/hooks/useGTM.js'
const Index = () => {
  React.useEffect(()=>{
    useGTMInit('support','adapter')
  },[])
  return (
    <Layout  menuBlack={true}>
      <SEO page="Support" />
      <DetailHeader pro='adapter' />
      <DetailStart updateLink='/support/update/#ada' manualsLink='/support/XREAL_ADA_MANUAL_pdf' />
      <DetailFaq type='adapter' />

    </Layout>
  )
}

export default Index